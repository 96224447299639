import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

// Check if window is defined
const isBrowser = typeof window !== "undefined"

const myStorage = isBrowser && window.localStorage;

export default class ResetPage extends React.Component {

    componentDidMount() {
        isBrowser && myStorage.removeItem('submit');
    }

    render() {
        return(
            <Layout>
                <SEO title="Reset formulára" />
                <div className="flex items-center min-h-screen bg-gray-50 dark:bg-gray-900">
                <div className="container mx-auto">
                <div className="items-center justify-center" >
                <div className="bg-white border py-2 px-5 rounded-lg flex items-center flex-col">
            
                <div className="text-gray-600 text-2xl font-black mt-2 text-center"></div>
                <h1 >Reset formulára</h1>
                </div></div></div></div>
            </Layout>
        );
    }
}